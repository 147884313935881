import React from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from 'components/layout'
import MetaTags from 'components/MetaTags'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import Header from 'components/Header'
import List from 'components/List'
import GridRow from 'components/grid/GridRow'
import GridItem from 'components/grid/GridItem'
import { css } from 'styled-components'

const PressReview = ({ data }) => {
  const {
    headerImage: {
      childImageSharp: { fluid },
    },
    prismic: {
      allPress_review_articles: { edges: articles = [] },
    },
  } = data
  const intl = useIntl()

  return (
    <Layout>
      <MetaTags
        title={`${intl.formatMessage({
          id: 'pressReview',
        })} | Hotel Griffintown`}
      />
      <Header
        // eyebrow={cmsData.eyebrow[0].text}
        heading={intl.formatMessage({ id: 'pressReview' })}
        fluid={fluid}
      />
      <Section>
        <Wrapper size='narrow'>
          <SectionBlock>
            <GridRow>
              <GridItem xs='1-1' lg='3-4'>
                <h2>Articles</h2>
                <List
                  // heading={intl.formatMessage({ id: 'articles' })}
                  items={articles.map(({ node }) => {
                    return (
                      <>
                        <h3
                          css={css`
                            margin-bottom: 0;
                          `}
                        >
                          <a
                            href={node.link.url}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {node.title}
                          </a>
                        </h3>

                        <a
                          href={node.link.url}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {node.link.url}
                        </a>
                      </>
                    )
                  })}
                />
              </GridItem>
            </GridRow>
          </SectionBlock>
        </Wrapper>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query PressReviewPage($language: String!) {
    headerImage: file(name: { eq: "img-hero-policies" }) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 100, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    prismic {
      allPress_review_articles(lang: $language) {
        edges {
          node {
            link {
              ... on PRISMIC__ExternalLink {
                target
                _linkType
                url
              }
              ... on PRISMIC__FileLink {
                _linkType
                name
                url
              }
            }
            title
          }
        }
      }
    }
  }
`

export default PressReview
